<template>
  <v-sheet class="setting-container">
    <div class="tu-container-title">
      <h2 class="page-title">个人设置</h2>
    </div>
    <v-tabs v-model="tab" icons-and-text height="48" style="margin-left:20px;">
      <v-tabs-slider width="124"></v-tabs-slider>
      <v-tab href="#tab-1"> 基本信息 </v-tab>
      <v-tab href="#tab-2"> 账号设置 </v-tab>
      <!-- <v-tab href="#tab-3">
                企业管理规定
      </v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item id="tab-1"> 
          <div class="titaui-aha-2021__body" style="height: calc(100vh - 74px);">
                <div class="titaui-aha-2021__body-content">
                    <div class="titaui-personal-info">
                        <div class="titaui-personal-info-base-info">
                            <div class="titaui-personal-info-base-info__header"><span
                                    class="titaui-personal-info-base-info__header-title">基本信息</span><span
                                    class="titaui-rect-btn titaui-rect-btn--primary titaui-rect-btn--normal" @click="editUserInfo">编辑</span></div>
                            <div class="titaui-personal-info-base-info__form">
                                <div class="titaui-personal-info-base-info__form-title">姓名</div><span
                                    class="titaui-personal-info-base-info__form-value">{{ authUser.employeeName }}</span>
                            </div>
                            <div class="titaui-personal-info-base-info__form">
                                <div class="titaui-personal-info-base-info__form-title">手机号码</div><span
                                    class="titaui-personal-info-base-info__form-value">{{ authUser.phone }}</span>
                            </div>
                            <div class="titaui-personal-info-base-info__form">
                                <div class="titaui-personal-info-base-info__form-title">电子邮箱</div><span
                                    class="titaui-personal-info-base-info__form-value">{{ authUser.email }}</span>
                            </div>
                        </div>
                        <div class="titaui-personal-info-person-photo">
                            <div class="titaui-personal-info-person-photo__header"><span
                                    class="titaui-personal-info-person-photo__header-title">上传头像</span></div>
                            <div class="titaui-personal-info-person-photo__main">
                                <div class="titaui-pc-avator-container">
                                    <div class="titaui-pc-avator__delete-wrapper ">
                                        <img width="6" height="6"
                                            src=""></div>
                                    <div class="titaui-pc-avator-wrapper">
                                        <div class="titaui-pc-avatar--text" style="width: 160px; height: 160px; background-color: rgb(245, 177, 104); font-size: 24px;">
                                            {{ authUser.employeeName.slice(0,1) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="titaui-personal-info-person-photo__main-change">点击修改</div>
                                <span class="titaui-personal-info-person-photo__main-tip">建议图片最小尺寸150*150px</span>
                            </div>
                        </div>
                    </div>
                </div><span></span>
            </div>


      </v-tab-item>
      <v-tab-item id="tab-2">

        <div class="setting-base-layout__content">
          <div style="height: calc(100vh - 120px)">
            <div class="account-setting-item">
              <div>
                <div class="account-setting-item__label">邮箱</div>
                <div class="account-setting-item__content">
                  {{ authUser.email }}
                </div>
              </div>
              <!-- <div class="account-setting-item__action">
                <span class="titaui-rect-btn titaui-rect-btn--cancel titaui-rect-btn--normal">更改</span>
              </div> -->
            </div>
            <div class="account-setting-item">
              <div>
                <div class="account-setting-item__label">手机号</div>
                <div class="account-setting-item__content">{{ authUser.phone }}</div>
              </div>

              <!-- <div class="account-setting-item__action">
                <span
                  class="titaui-rect-btn titaui-rect-btn--cancel titaui-rect-btn--normal"
                  style="margin-right: 10px"
                  >更改</span>
                <span class="titaui-rect-btn titaui-rect-btn--primary titaui-rect-btn--normal">解绑</span>
              </div> -->

            </div>
            <div class="account-setting-item">
              <div>
                <div class="account-setting-item__label">密码</div>
                <div class="account-setting-item__content">已设置</div>
              </div>
              <div class="account-setting-item__action">
                <span class="titaui-rect-btn titaui-rect-btn--primary titaui-rect-btn--normal" @click="resetPasswd">修改</span>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <!-- <v-tab-item id="tab-3">
                <EnterpriseManagement />
            </v-tab-item> -->
    </v-tabs-items>

    <!-- 修改信息 -->
    <v-dialog persistent v-model="dialog" max-width="700">
      <v-card>
        <v-toolbar height="35" color="primary" dark>
          <v-container>
            <v-row>
              <v-col>
                编辑信息
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" class="pl-5">
                <v-icon @click="cancel">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <v-card-text>
          <v-container class="pa-5" fluid  style="margin-top:30px;">
            <v-row>
              <div class="text-caption">姓名*</div>
            </v-row>
            <v-row>
              <v-text-field v-model="authUser.employeeName" dense placeholder="请输入" outlined></v-text-field>
            </v-row>
            <v-row>
              <div class="text-caption">手机号*</div>
            </v-row>
            <v-row>
              <v-text-field v-model="authUser.phone" dense placeholder="请输入" outlined></v-text-field>
            </v-row>
            <!-- <v-row>
              <div class="text-caption">密码*</div>
            </v-row>
            <v-row>
              <v-text-field v-model="newUser.user_password" dense type="password" placeholder="请输入" outlined></v-text-field>
            </v-row> -->
            <v-row>
              <div class="text-caption">邮箱*</div>
            </v-row>
            <v-row>
              <v-text-field v-model="authUser.email" dense placeholder="请输入" outlined></v-text-field>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
          <v-container class="d-flex justify-end">
            <v-btn class="mx-1" small @click="cancel">取消</v-btn>
            <v-btn class="mr-7 ml-1" small @click="updateInfo" color="primary">确认</v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 修改密码 -->
    <v-dialog persistent v-model="dialogPwd" max-width="700">
        <v-form
          ref="formPwd"
          v-model="valid"
          lazy-validation
        >
        <v-card>
          <v-toolbar height="35" color="primary" dark>
            <v-container>
              <v-row>
                <v-col>
                  修改密码
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1" class="pl-5">
                  <v-icon @click="cancelPwd">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
          <v-card-text>
            <v-container class="pa-5" fluid  style="margin-top:30px;">

              <v-row>
                <div class="text-caption">新密码*</div>
              </v-row>
              <v-row>
                <v-text-field :rules="pwdRules" required v-model="new_password" dense type="password"  placeholder="请输入新密码" outlined clearable></v-text-field>
              </v-row>

              <v-row>
                <div class="text-caption">确认新密码*</div>
              </v-row>
              <v-row>
                <v-text-field :rules="confirmPwdRules" required v-model="confirm_password" dense type="password" placeholder="请确认新密码" outlined clearable></v-text-field>
              </v-row>

              <!-- <v-row>
                <div class="text-caption">邮箱*</div>
              </v-row>
              <v-row>
                <v-text-field v-model="authUser.email" dense placeholder="请输入" outlined></v-text-field>
              </v-row> -->

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-container class="d-flex justify-end">
              <v-btn class="mx-1" small @click="cancelPwd">取消</v-btn>
              <v-btn class="mr-7 ml-1" small @click="updatePwd" color="primary">确认</v-btn>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </v-sheet>


</template>

<script>
  // import EnterpriseManagement from '@/components/setting/EnterpriseManagement.vue';
import '@/assets/tita_ui.css';
import {
  mapGetters,
  mapActions
} from "vuex";

import {
  getUser
} from '@/utils/authUser.service';

  export default {
    name: "SettingPage",
    components: {
      //EnterpriseManagement
    },
    data() {
      return {
        tab: null,

        //编辑用户信息
        dialog: false,
        dialogPwd:false,
        resolve: null,
        reject: null,

        //user_password:"",       //用户密码
        new_password:"",        //新密码
        confirm_password:"",    //确认密码

        //确认密码校验
        valid: true,
        pwdRules: [          
          v => !!v || "请输入新密码",
          v => v.length >= 6 || "新密码不能低于6位",
          v => v.search(/\s/) === -1 || "不能有空格"
        ],

        confirmPwdRules: [
          v => !!v || '请确认新密码',
          v => v.length >= 6 || "密码不能低于6位",
          v => v.search(/\s/) === -1 || "不能有空格"
        ],

      };
    },
    mounted(){
        this.show();
    },
    computed: {
        ...mapGetters("auth", ["authUser"]),
        ...mapGetters("user", ["users"]),
        ...mapGetters("app", ["okrFilter", "isUser", "pickedUser"]),
    },
    methods:{
        ...mapActions("user", ["updateUser", "getUsers"]),
        ...mapActions('app', ['setNotification']),    //公共弹层方法
        
        ...mapActions("auth", ["signup"]),    //用户注册
        
        show(){
            //权限用户
            console.log(this.authUser,'authUser')
            // console.log(this.pickedUser,'pickedUser')

        },

        //编辑用户弹层
        editUserInfo() {
          this.dialog = true
        },

        updateInfo() {
          
          let userInfo = {
            id:this.authUser.id,
            employeeName: this.authUser.employeeName,
            phone: this.authUser.phone,
            email: this.authUser.email,
          }
          // console.log(userInfo,'userInfo------')
          this.updateUser(userInfo)
          this.dialog = false
        },
        cancel() {
          this.dialog = false
        },

        //修改密码
        resetPasswd(){
          this.dialogPwd = true
        },
        cancelPwd() {
          this.dialogPwd = false
        },
        updatePwd(){
          this.$refs.formPwd.validate();
          if (this.confirm_password !== this.new_password || this.confirm_password.length==0||this.new_password.length==0) {
            this.setNotification({
              status: 'warning',
              text: '两次密码不一致'
            });
          }
          else{
              let params = {
                id:this.authUser.id,
                user_password : this.new_password,

              }
              console.log(params,'param------')
              this.updateUser(params)
              this.dialogPwd = false
          }
          
        },

    },
  };
</script>

<style scoped>
  .setting-container {
    /* padding: 10px 20px; */
  }

  .tu-container-title {
    background-color: #f4f6f9;
    padding: 20px;
  }
  .page-title {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #141c28;
  }

  /*new */






</style>
